// Elements
const bodyElement = document.body;

export const responsiveBreakpoints = {
    desktop: 0,
    tabletLandscape: 0,
    tabletPortrait: 0,
    mobileLandscape: 0,
    mobilePortrait: 0,
    mobileSmall: 0
};

export const doesElementHaveParentWithClass = (element, className) => {
    if (element) {
        if (
            element.className &&
            typeof element.className === "string" &&
            element.className.indexOf(className) > -1
        ) {
            return true;
        } else {
            return doesElementHaveParentWithClass(
                element.parentElement,
                className
            );
        }
    } else {
        return false;
    }
};

export const getAhrefParent = (element, counter) => {
    if (element && counter < 10) {
        if (element.href) {
            return element;
        } else {
            counter++;
            return getAhrefParent(element.parentElement, counter);
        }
    } else {
        return false;
    }
};

export const getParentElementWithTag = (element, htmlTagName) => {
    if (element) {
        if (element.tagName.toLowerCase() === htmlTagName) {
            return element;
        } else {
            return getParentElementWithTag(element.parentElement, htmlTagName);
        }
    } else {
        return false;
    }
};

export const getParentElementWithTagAndClass = (
    element,
    htmlTagName,
    className
) => {
    if (element) {
        if (
            element.tagName.toLowerCase() === htmlTagName &&
            element.className.indexOf(className) > -1
        ) {
            return element;
        } else {
            return getParentElementWithTagAndClass(
                element.parentElement,
                htmlTagName,
                className
            );
        }
    } else {
        return false;
    }
};

export const getParentElementWithClass = (element, className) => {
    if (element) {
        if (element.className.indexOf(className) > -1) {
            return element;
        } else {
            return getParentElementWithClass(element.parentElement, className);
        }
    } else {
        return false;
    }
};

export const displaySiteOverlay = cssClass => {
    bodyElement.classList.add("v-site-overlay-open");
    if (cssClass) {
        bodyElement.classList.add(cssClass);
    }
};

export const hideSiteOverlay = cssClass => {
    bodyElement.classList.remove("v-site-overlay-open");
    if (cssClass) {
        bodyElement.classList.remove(cssClass);
    }
};

export const elementInViewport = el => {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    let width = el.offsetWidth;
    let height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
    );
};
export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};
