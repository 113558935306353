import ResponsiveAutoHeight from "responsive-auto-height";
import {
    elementInViewport,
    getParentElementWithTagAndClass
} from "./utilities";
import { initScrollReveal } from "./scroll-reveal";
import "lottie-web";
import GLightbox from "glightbox/src/js/glightbox";
import StickySidebar from "sticky-sidebar";
import swiper from "swiper";

/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

/*==========================================
 Multiselect Dropdown
===========================================*/
let expanded = false;

function showCheckboxes() {
    const checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
        checkboxes.style.display = "block";
        expanded = true;
    } else {
        checkboxes.style.display = "none";
        expanded = false;
    }
}

document.querySelector(".selectBox").addEventListener("click", function() {
    showCheckboxes();
});

/*==========================================
 Click Events Listeners
===========================================*/

bodyElement.addEventListener("click", evt => {});

/*==========================================
 Header Mobile Menu
===========================================*/
// document.querySelector(".a-search-icon").addEventListener("click", function() {
//     document
//         .querySelector(".m-search-input-block")
//         .classList.toggle("v-search-open");
// });

bodyElement.addEventListener("click", evt => {
    const clickedElement = evt.target;

    const searchButton = getParentElementWithTagAndClass(
        clickedElement,
        "div",
        "a-search-icon"
    );

    if (searchButton) {
        document
            .querySelector(".m-search-input-block")
            .classList.toggle("v-search-open");
    }
});

[].slice
    .call(document.querySelectorAll(".mobile-chevron"))
    .forEach(function(ele) {
        ele.addEventListener("click", function(e) {
            e.preventDefault();
            var element = this;

            [].slice
                .call(document.querySelectorAll(".mobile-chevron"))
                .forEach(function(item) {
                    if (element.classList.contains("v-active")) {
                    } else {
                        item.classList.remove("v-active");
                    }
                });

            this.classList.toggle("v-active");

            var dropdown = this.closest(".a-nav-type ").nextElementSibling;

            if (dropdown.style.display === "block") {
                dropdown.style.display = "none";
            } else {
                [].slice
                    .call(document.querySelectorAll(".nav-dropdown"))
                    .forEach(function(item) {
                        item.style.display = "none";
                    });
                dropdown.style.display = "block";
            }
        });
    });

document.querySelector(".a-menu-icon").addEventListener("click", function() {
    this.classList.toggle("v-closed");
    this.classList.toggle("v-active");
    document.querySelector(".header-nav").classList.toggle("v-visible");
});

/*==========================================
Sticky Sidebar
===========================================*/
let stickySidebar;

if (document.querySelector(".sidebar")) {
    stickySidebar = new StickySidebar(".sidebar", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".o-faq-section",
        innerWrapperSelector: ".sidebar__inner",
        resizeSensor: true
    });
}

/*==========================================
FAQs Tabs + Accordion
===========================================*/

document.addEventListener("click", function(event) {
    if (!event.target.classList.contains("accordion-toggle")) return;
    var content = document.querySelector(event.target.hash);

    if (!content) return;
    event.preventDefault();

    if (content.classList.contains("v-active")) {
        content.classList.remove("v-active");
        stickySidebar.updateSticky();
        return;
    }

    var accordions = [].slice.call(
        document.querySelectorAll(".accordion-content.v-active")
    );

    for (var i = 0; i < accordions.length; i++) {
        accordions[i].classList.remove("v-active");
        stickySidebar.updateSticky();
    }

    content.classList.toggle("v-active");
    stickySidebar.updateSticky();
});

function onTabClick(event) {
    let activeTabs = [].slice.call(document.querySelectorAll(".v-active"));

    activeTabs.forEach(function(tab) {
        tab.className = tab.className.replace("v-active", "");
    });

    event.target.parentElement.className += " v-active";
    document.getElementById(event.target.href.split("#")[1]).className +=
        " v-active";
    stickySidebar.updateSticky();
}

const element = document.getElementById("nav-tab");

if (element) {
    element.addEventListener("click", onTabClick, false);
}

/*==========================================
Swiper
===========================================*/
const logoSwiper = new swiper(".logo-carousel-swiper", {
    slidesPerView: 6,
    spaceBetween: 24,
    loop: true,
    breakpoints: {
        600: {
            slidesPerView: 3
        },
        800: {
            slidesPerView: 4
        }
    },
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    }
});

const quoteSwiper = new swiper(".quotes-container", {
    fadeEffect: { crossFade: true },
    slidesPerView: 1,
    effect: "fade",
    allowTouchMove: false,
    pagination: {
        el: ".swiper-pagination.v-quote",
        clickable: true
    }
});

const featuredLots = new swiper(".featured-lots-container", {
    direction: "horizontal",
    slidesPerView: 3,
    spaceBetween: 24,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    pagination: {
        el: ".swiper-pagination.v-featured",
        clickable: true
    },
    breakpoints: {
        600: {
            slidesPerView: 1
        },
        800: {
            slidesPerView: 2
        }
    }
});

const productSwiper = new swiper(".o-product-slider-section", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    pagination: {
        el: ".swiper-pagination.v-product",
        clickable: true
    }
});

/*==========================================
Lottie
===========================================*/
const players = [].slice.call(document.querySelectorAll("lottie-player"));

players.forEach(element => {
    let animationName = element.getAttribute("id");

    lottie.loadAnimation({
        name: animationName,
        container: element,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: element.getAttribute("src")
    });

    if (elementInViewport(element)) {
        setTimeout(() => {
            lottie.play(animationName);
        }, 1000);
    }

    window.addEventListener("scroll", () => {
        if (elementInViewport(element)) {
            setTimeout(() => {
                lottie.play(animationName);
            }, 1000);
        }
    });
});

/*==========================================
Lightbox
===========================================*/
const lightbox = GLightbox({
    autoplayVideos: true,
    selector: ".glightbox"
});

/*==========================================
Equal Height
===========================================*/
const runMatchHeight = () => {
    if (document.querySelector(".match-height")) {
        new ResponsiveAutoHeight(".match-height");
    }

    if (document.querySelector(".m-base-card")) {
        new ResponsiveAutoHeight(".m-base-card h3");
        new ResponsiveAutoHeight(".m-base-card p");
        new ResponsiveAutoHeight(".m-base-card a");
        new ResponsiveAutoHeight(".m-base-card .card-content");
        new ResponsiveAutoHeight(".m-base-card");
    }

    if (document.querySelector(".m-simple-auction-card")) {
        new ResponsiveAutoHeight(".m-simple-auction-card h3");
        new ResponsiveAutoHeight(".m-simple-auction-card p");
        new ResponsiveAutoHeight(".m-simple-auction-card");
    }
};

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();
        runMatchHeight();
        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Site Search
===========================================*/

const siteSearch = document.querySelector("#site-search");

if (siteSearch) {
    const radioList = siteSearch.querySelector(".radio-list");
    if (radioList) {
        const textInput = document.querySelector("input[type='text']");

        siteSearch.addEventListener("submit", e => {
            e.preventDefault();

            const checkRadioButton = siteSearch.querySelector(
                'input[name="search-radio"]:checked'
            );

            const newActionUrl = checkRadioButton.getAttribute(
                "data-search-url"
            );
            const dataKey = checkRadioButton.getAttribute("data-key");
            textInput.setAttribute("name", dataKey);

            siteSearch.setAttribute("action", newActionUrl);

            siteSearch.submit();
        });
    }
}
