import ScrollReveal from "scrollreveal";

/*==========================================
  Scroll Reveal 
===========================================*/

const scrollRevealAfterCallback = el => {
    el.classList.add("v-sr-is-visible");
};

const scrollRevealBeforeCallback = el => {
    el.classList.add("v-sr-before-visible");
};

export const srDelayFadeOptions = {
    distance: "0px",
    viewFactor: 0.5,
    duration: 2000,
    delay: 500,
    cleanup: true,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srFadeOptions = {
    distance: "0px",
    viewFactor: 0.3,
    duration: 200,
    cleanup: true,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srIntervalOptions = {
    distance: "16px",
    viewFactor: 0.3,
    duration: 200,
    cleanup: true,
    interval: 300,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srIntervalLTROptions = {
    distance: "16px",
    viewFactor: 0.5,
    duration: 600,
    cleanup: true,
    interval: 300,
    origin: 'left',
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srLeftFadeOptions = {
    distance: "24px",
    viewFactor: 0.5,
    duration: 1000,
    cleanup: true,    
    origin: 'left',
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srRightFadeOptions = {
    distance: "24px",
    viewFactor: 0.5,
    duration: 1000,
    cleanup: true,
    origin: 'right',
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const initScrollReveal = () => {
    const scrollRevealSelectors = "";

    ScrollReveal().reveal(scrollRevealSelectors, srDelayFadeOptions);
    ScrollReveal().reveal(scrollRevealSelectors, srFadeOptions);
    ScrollReveal().reveal(scrollRevealSelectors, srIntervalOptions);

    if (document.querySelector(".v-scroll-reveal-delay-fade")) {
        ScrollReveal().reveal(
            ".v-scroll-reveal-delay-fade",
            srDelayFadeOptions
        );
    }

    if (document.querySelector(".v-scroll-reveal-fade")) {
        ScrollReveal().reveal(".v-scroll-reveal-fade", srFadeOptions);
    }

    if (document.querySelector(".v-scroll-reveal-interval")) {
        ScrollReveal().reveal(".v-scroll-reveal-interval", srIntervalOptions);
    }

    if (document.querySelector(".v-scroll-reveal-interval-ltr")) {
        ScrollReveal().reveal(".v-scroll-reveal-interval-ltr", srIntervalLTROptions);
    }

    if (document.querySelector(".v-scroll-reveal-interval-ltr")) {
        ScrollReveal().reveal(".v-scroll-reveal-interval-ltr", srIntervalLTROptions);
    }

    if (document.querySelector(".v-scroll-reveal-left")) {
        ScrollReveal().reveal(".v-scroll-reveal-left", srLeftFadeOptions);
    }

    if (document.querySelector(".v-scroll-reveal-right")) {
        ScrollReveal().reveal(".v-scroll-reveal-right", srRightFadeOptions);
    }
};
